body {
	margin: 0;
	font-family: 'PT Sans';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow-x: hidden;
}

.navbar-brand {
	font-family: 'PT Sans';
}

.flexbox {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: column;
}

.back-up-container {
	position: fixed; /* Fixed/sticky position */
	bottom: 50px; /* Place the button at the bottom of the page */
	right: -100px; /* Place the button 30px from the right */
	z-index: 99; /* Make sure it does not overlap */
	border: none; /* Remove borders */
	outline: none; /* Remove outline */
	transition: right 0.5s ease !important;
}
.back-up-transition {
	right: 50px;
}

.modal {
	z-index: 9998;
	position: absolute;
	top: 0;
	left: 0;
	pointer-events: none;
	width: 100vw;
	height: 100vh;
	background: white;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}

.order-link {
	cursor: pointer;
	transition: color 0.3s ease;
	user-select: none;
}
.order-link:hover {
	color: #dc3545;
}

.ie11-img-hack {
	margin-top: 16px !important;
}
